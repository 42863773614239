/**
 * Box-sizing adjustment: http://blog.teamtreehouse.com/box-sizing-secret-simple-css-layouts
 */
html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}





/**
 *
 */
html,
head {
	width: 100%;
	height: 100%;
}





/**
 * Force problematic browsers to use original font-size.
 * Force font-smoothing.
 * Remove tap delay: https://developers.google.com/web/updates/2013/12/300ms-tap-delay-gone-away
 */
html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	touch-action: manipulation;
}





/**
 * General page settings.
 *
 * Pages are scaled to certain widths depending on device size.
 * - Phone-sized devices are locked to 718px.
 * - Bigger phone and smaller tablet devices are locked to 810px.
 * - Tablet and desktop use full width of window.
 */
body {
	width: 718px;
	height: 100%;

	background-color: $body-background-color;
	color: $body-color;

	overflow-y: scroll;


	@include for-phone-landscape-up {
		width: 810px;
	}

	@include for-desktop-up {
		width: 1038px;
	}

	@include for-desktop-big-up {
		width: 100%;
	}
}





/**
 * Main container. Using table display.
 * TODO: convert to css grid.
 */
.l-site-container {
	position: relative;

	display: flex;
	flex-direction: column;

	width: 100%;
	height: 100%;
	min-height: 100vh;

	// TODO: testing
	@include theme('akatsuki-dawn') {
		background-color: $test-color;
	}
}



.l-site-stripped {
	position: relative;

	background-color: #1b1209;


	&__inner {
		display: inline-flex;

		padding: 10px;
	}
}




/**
 * Main sections setup.
 */
.l-main {
	flex: 1 0 auto;
}





/**
 * Footer sections setup.
 */
.l-footer {
	flex: 1 0 auto;
}



/*
.l-header,
.l-footer,
.l-main {
	display: block;
	display: table-row;
}

.l-header,
.l-footer {
	height: 1px;
}
*/
