/**
 * General footer section settings.
 */
.l-footer {

}





/**
 * Footer container with background image and pushing up content.
 */
.footer-container {
	margin-top: -40px;

	background: url('#{$gfx-path}/themes/#{$theme}/shared/bg_footer.png') no-repeat center top $footer-container-background-color;
}





/**
 * Inner content container.
 */
.footer-inner {
	position: relative;

	max-width: 720px;
	min-height: 500px;
	margin: 0 auto;
	padding-bottom: 70px;


	&__content {
		padding: 120px 50px 25px 20px;
	}

	&__bottom {
		padding: 10px 10px 20px 10px;
	}



	@include for-phone-landscape-up {
		max-width: 810px;
		padding-bottom: 0;


		&__content {
			padding: 120px 50px 25px 20px;

			.menu-table {
				.menu-cell {
					display: table-cell;
				}
			}
		}

		&__bottom {
			padding: 10px 80px 20px 10px;
		}
	}

	@include for-desktop-up {
		max-width: 1038px;


		&__content {
			padding: 120px 50px 25px 140px;
		}

		&__bottom {
			padding: 10px 160px 20px 50px;
		}
	}
}





/**
 * Bottom footer container.
 */
.footer-content {
	min-height: 450px;
}





/**
 * Footer site map container.
 */
.site-map-container {
	display: table;


	&__cell {
		display: table-cell;
		width: 230px;

		vertical-align: top;


		&:nth-child(1) {
			padding-top: 10px;
		}

		&:nth-child(2) {
			width: 230px;

			.menu-box {
				margin-right: 15px;
			}
		}

		&:nth-child(3) {
			width: 175px;
			padding-top: 45px;
		}
	}
}


/**
 * Footer site map.
 */
.site-map {
	margin: 0 25px 25px 0;


	&__title {
		position: relative;

		padding-right: 30px;

		font-size: 1rem;
		font-weight: 700;
		text-shadow: 1px 1px 2px $footer-site-map-title-shadow-color;

		color: $footer-site-map-title-color;


		a {
			color: inherit;
			text-decoration: none;
		}

		i {
			position: absolute;
			top: -3px;
			right: 0;

			font-size: 24px;

			color: $footer-site-map-title-icon-color;
		}
	}

	&__line {
		height: 2px;
		margin-top: -3px;

		background: linear-gradient(to right, $footer-site-map-line-background-gradient-from-color, rgba($footer-site-map-line-background-gradient-to-color, 0)); // TODO: test, transparent gradient bug fix for older ios


		&:after {
			top: 2px;
			height: 2px;
		}
	}

	&__list {
		display: table;
		width: 100%;
	}
}


/**
 * Site map ul list.
 */
.site-map-list {
	list-style-type: none;

	width: 100%;
	margin: 5px 0;
	padding: 0;


	li {
		position: relative;

		padding: 0 5px 0 15px;

		font-size: 0.7rem;

		color: $footer-site-map-list-color;


		&:before {
			@extend %font-nm;

			content: "\e83b";

			position: absolute;
			top: 2px;
			left: 0;

			font-size: 10px;
			text-shadow: 1px 1px 1px $footer-site-map-title-icon-shadow-color;

			color: $footer-site-map-list-icon-color;
		}

		a {
			text-decoration: underline;

			color: $footer-site-map-list-link-color;


			&:hover {
				color: $footer-site-map-list-link-hover-color;
			}
		}


		ul {
			float: none;
			width: 100%;
			margin: 2px 0 5px 0;
		}
	}


	&.-width-half {
		float: left;
		width: 50%;


		li {
			ul {
				float: none;
			}
		}
	}
}





/**
 * Bottom footer container.
 */
.footer-bottom {
	text-align: center;
	text-shadow: 1px 1px 1px $footer-bottom-shadow-color;


	&__copyright {
		font-size: 0.65rem;

		color: $footer-bottom-copyright-color;
	}

	&__links {
		line-height: 1.2;

		font-size: 0.65rem;


		span {
			margin: 0 3px;

			a {
				text-decoration: underline;

				color: $footer-bottom-links-link-color;
			}
		}
	}
}
