/**
 * General header section settings.
 */
.l-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	display: block;
	height: 90px;
	margin: 0 auto;

	background: $header-background-color;

	backface-visibility: hidden;


	&:before {
		content: '';

		position: absolute;
		top: 96px;
		left: 0;
		z-index: 1;

		width: 100%;
		height: 2px;

		background-color: rgba($header-before-background-color, 0.2);// rgba(#421109, 0.5);
	}

	&:after {
		content: '';

		position: absolute;
		top: 90px;
		left: 0;
		z-index: 1;

		display: block;
		width: 100%;
		height: 6px;

		background-color: $header-after-background-color;
		border-top: 1px solid $header-after-border-top-color;
		border-bottom: 2px solid #251604;//311109;//1f0904;//421109;

		box-shadow: none; // TODO: remove
	}


	&--beta {

		&:after {
			background-color: $header-after-background-color-beta;
		}


		.c-notification {
			display: flex;
		}
	}

	&.-c-user-open {
		/**
		 * Affects:
		 *      .header-outside
		 *      .header-inside
		 */
	}



	@include for-phone-landscape-up {
		position: relative;
		top: auto;
		left: auto;

		flex: 0 0 90px;

		display: block;

		background: linear-gradient($color-morocco-brown, $color-cocoa-brown);
	}

	@include for-tablet-up {
		width: 100%;
	}
}





/*
 * Outside part of the header, with content being hidden if not wide enough screen.
 */
.header-outside {
	position: absolute;
	left: 0;
	right: 0;

	display: block;
	width: 810px;
	margin-left: auto;
	margin-right: auto;

	overflow: hidden;


	&__outer {
		position: relative;
		top: 0;

		display: block;
		width: 1038px;
		height: 90px;
		margin: 0 auto;
	}

	&__logo {
		position: absolute;
		top: 0;
		left: -150px;

		display: none;
		width: 175px;
		height: 90px;

		background: url('#{$gfx-path}/themes/#{$theme}/shared/logo_shinshinobi.png') 50% 88% no-repeat;
	}

	&__user {
		position: absolute;
		top: 0;
		right: 130px;

		display: flex;
		flex-direction: row;

		width: 190px;
		height: 90px;

		border-left: 2px solid $header-user-border-color;

		z-index: 3;


		&:before {
			content: none;

			position: absolute;
			top: 0;
			left: -5px;

			width: 3px;
			height: 100%;

			background: $header-user-before-background-color;
		}


		.-c-user-open & {
			right: 320px;

			transition: all 0.5s ease;


			&:before {
				background-color: rgba(#000, 0.25);
			}
		}
	}

	&__avatar {
		position: relative;

		height: 100%;
		width: 90px;

		overflow: hidden;

		&:after {
			content: "";

			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;

			border: 1px solid;
			border-color: $header-user-avatar-border-top-color
						 $header-user-avatar-border-right-color
						 $header-user-avatar-border-bottom-color
						 $header-user-avatar-border-left-color;
		}
	}

	&__avatar-image {
		width: 90px;
		height: 100px;
	}

	&__avatar-name {
		position: absolute;
		right: 1px;
		bottom: 1px;
		left: 1px;

		text-align: right;
		line-height: 1;

		background: linear-gradient(to right, rgba($header-user-name-background-color, 0.4), $header-user-name-background-color);


		span {
			padding: 0 5px;

			font-size: 0.7rem;
			line-height: 2;
			text-decoration: underline;

			color: $header-user-name-color;
			text-shadow: 1px 1px 1px $shadow-color-strongest;
		}
	}

	&__options {
		position: relative;

		display: flex;
		flex-direction: column;

		height: 100%;
		width: 98px;
	}

	&__user-toggle {
		position: absolute;
		top: 0;
		left: -50px;

		width: 50px;
		height: 33px;
		padding-top: 3px;
		padding-right: 12px;

		text-align: right;

		background: $header-user-toggle-background-color;
		background: linear-gradient(to right, $header-user-toggle-background-color-gradient, $header-user-toggle-background-color);

		cursor: pointer;


		&:after {
			@extend %font-nm;

			content: '\e877';

			font-size: 20px;

			color: $header-user-toggle-after-color;
			text-shadow: 1px 1px 1px $shadow-color-strongest;
		}


		.-c-user-open & {
			background: #1D150D;

			&:after {
				content: '\e844';
			}
		}
	}


	@include for-phone-landscape-up {
		&__user {
			right: 40px;

			&:before {
				content: "";
			}


			.-c-user-open & {
				right: 225px;

				background: linear-gradient($color-morocco-brown, $color-cocoa-brown);
			}
		}

		&__user-toggle {
			&:after {
				content: '\e877';
			}
		}
	}

	@include for-desktop-up {
		width: auto;
		min-width: 1038px;

		&__logo {
			display: block;
		}

		&__user {
			right: -170px;


			.-c-user-open & {
				right: 0;
			}
		}
	}

	@media screen and (min-width: 1410px) {
		&__user-toggle {
			display: none;
		}
	}
}





/**
 * Inside part of the header.
 */
.header-inside {
	$c: &;

	position: relative;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	width: 718px;
	max-width: 718px;
	height: 100%;
	margin: 0 auto;


	&__logo {
		position: relative;

		flex: 0 0 auto;

		display: none;
		width: 100px;
		height: 90px;

		background-size: 97px 34px;

		cursor: pointer;


		.c-notification {
			position: absolute;
			right: 25px;
			top: 1px;

			span {
				padding: 0;
			}
		}
	}

	&__leftcol-top {
		position: absolute;
		right: 33px;
		bottom: -96px;

		display: none;
		width: 159px;
		height: 112px;

		background: 0 0 no-repeat;

		z-index: 50;
	}

	&__lineup {
		position: relative;

		flex: 0 0 auto;

		width: 498px;

		z-index: 3;
	}

	&__lineup-inner {
		margin-left: 10px;

		z-index: 3;


		// component
		.c-card {
			&__data {
				display: none;
			}
		}

		// component
		.c-levelup {
			position: absolute;
			top: 80px;
			right: 0;
			left: -20px;

			width: 190px;
			margin: 0 auto;

			border-radius: 0 0 2px 2px;

			cursor: pointer;

			z-index: 2;

			opacity: 0;


			&.-c-visible {
				animation: 0.5s fadeInUp forwards;
			}

			&.-c-unset {
				animation: unset;

				opacity: 1;
			}
		}
	}

	&__lineup-chakra {
		top: 1px;
		right: 25px;


		&.c-resource {
			position: absolute;

			width: 88px;
			height: 25px;

			text-align: right;
			line-height: 1.3;
		}
	}

	&__lineup-edit {
		top: 31px;
		right: 25px;

		z-index: 6;

		&.c-button {
			position: absolute;

			height: 35px;
			width: 80px;


			span {
				top: -1px;

				font-size: 0.65rem;
			}
		}
	}

	&__site-title {
		position: absolute;

		height: 79px;
		margin-right: 110px;

		overflow: hidden;


		h1,
		h2,
		p {
			margin: 0;

			color: $header-title-color;

			font-size: 0.8rem;
			line-height: 1.2;
		}

		h1 {
			margin-top: 20px;

			color: $header-title-h1-color;

			font-size: 1.4rem;
		}

		h2 {
			font-size: 1rem;
		}
	}

	&__team {
		position: absolute;
		right: 0;
		top: 0;

		flex: 0 0 auto;

		width: 220px;
		height: 100%;
		margin-left: 0;

		background: $header-team-background-color;


		// user panel opened
		.-c-user-open & {
			&:after {
				content: '';

				position: absolute;
				left: 0;
				top: 0;

				width: 100%;
				height: 100%;

				background: rgba(#261c13, 0.8);

				z-index: 1;
			}
		}
	}

	&__account {
		background: linear-gradient(to right, #523C2B, rgba(52,38,24,0));


		&.-no-bg {
			&:before {
				content: '';

				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;

				width: 5px;
				height: 100%;

				background-color: #44321e;
			}


			#{$c}__account-register,
			#{$c}__account-login {
				&.c-button {
					left: 15px;
				}
			}
		}
	}

	&__account-register {
		&.c-button {
			position: absolute;
			top: 6px;
			left: 42px;

			z-index: 2;
		}
	}

	&__account-login {
		&.c-button {
			position: absolute;
			top: 45px;
			left: 42px;

			min-width: 170px;
		}
	}



	@include for-phone-landscape-up {
		width: 810px;
		max-width: 810px;
		margin-top: 0;


		&__logo {
			display: block;
			background-image: none;
		}

		&__leftcol-top {
			display: block;
		}

		&__lineup {
			width: 510px;
			margin-left: -20px;
		}

		&__lineup-inner {
			margin-left: 0;
		}

		&__lineup-chakra {
			right: 48px;
		}

		&__lineup-edit {
			right: 48px;
		}

		&__team {
			position: relative;
			right: auto;
			top: auto;
		}
	}

	@include for-desktop-up {
		max-width: 1038px;
		width: 1038px;


		&__logo {
			width: 225px;

			background: url('#{$gfx-path}/logo_medium.png') top 18px center no-repeat;
			background-size: auto;
			background-position: 19px center;
		}

		&__leftcol-top {
			right: 155px;
		}

		&__lineup {
			width: 490px;
			margin-left: 0;
			margin-right: 5px;
		}

		&__lineup-chakra {
			right: 28px;
		}

		&__lineup-edit {
			right: 28px;
		}

		&__team {
			width: 300px;
		}
	}
}


/**
 * Team section of .header-inside.
 */
.header-team {

	&:before {
		content: '';

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;

		width: 5px;
		height: 100%;

		background-color: #44321e;
	}


	&__bg {
		position: absolute;
		left: -40px;
		top: 0;

		display: none;

		width: 107px;
		height: 107px;

		background: 0 0 no-repeat;

		z-index: 2;
	}

	&__details {
		position: absolute;
		top: -3px;
		left: 10px;

		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		width: 190px;
		height: 32px;
	}

	&__details-rating {
		width: 55px;
		min-width: 55px;

		font-size: 0.675rem;
		text-align: right;
		text-shadow: 1px 1px 1px $shadow-color-strongest;

		color: #BD9D78;
	}

	&__details-rank {
		width: 30px;
		min-width: 30px;
	}

	&__details-name {
		flex: auto;

		font-size: 0.7rem;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-shadow: 1px 1px 2px $shadow-color-strongest;

		color: $header-team-name-color;

		overflow: hidden;

		span {
			padding: 0 1px;

			font-weight: 600;
		}

	}

	&__resource {
		top: 27px;


		&.c-resource {
			position: absolute;

			text-align: right;

			border-color: $header-team-resource-border-color;
		}
	}

	&__resource-gold {
		left: 20px;


		&.c-resource {
			width: 90px;
		}
	}

	&__resource-diamonds {
		left: 114px;


		&.c-resource {
			width: 55px;
		}
	}

	&__bar {
		&.c-bar {
			position: absolute;

			width: 180px;

			border-color: $header-team-resource-border-color;
		}
	}

	&__bar-ae {
		top: 54px;
		left: 25px;
	}

	&__bar-we {
		top: 69px;
		left: 25px;
	}


	@include for-desktop-up {
		&:before {
			content: none;
		}


		&__bg {
			display: block;
		}
	}
}
