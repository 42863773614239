/**
 * General main section settings.
 */
.l-main {
	background: #841734;



	@include for-desktop-up {
		background: url('#{$gfx-path}/themes/#{$theme}/shared/bg.png') 0 0 no-repeat #000;
	}

	@media screen and (min-width: 1900px) {
		background-size: 100% auto;
	}
}





/**
 * Top container between header and content.
 */
.top-container {
	display: block;
	height: 10px;
	margin: 95px auto 0 auto;


	&__achievements {
		position: relative;
		top: 6px;


		// component
		.c-achievement {
			position: absolute;
			left: 250px;

			width: 210px;
			min-height: 230px;

			border-color: #513C36;

			cursor: pointer;

			z-index: 101;
		}
	}


	@include for-phone-landscape-up {
		margin-top: 0;
		height: 50px;
	}
}





/**
 * Inner container holding content in place.
 *      menu-top
 *      content-container
 *          left-col
 *          right-col
 *          center-col
 *      bottomrow
 */
.inner-container {
	$c: &;

	position: relative;

	display: flex;
	flex-flow: column nowrap;

	width: 718px;
	max-width: 718px;
	min-height: 1000px;
	margin: 0 auto;

	background: #291d12;
	border-top: 3px solid #794c57;


	&:before {
		content: '';

		position: absolute;
		top: -5px;
		right: 0;
		bottom: 0;
		left: 0;

		border-top: 2px solid rgba(#000, 0.25);
	}


	&__overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		display: none;
		width: 100%;
		height: 100%;

		background-color: rgba(#000, 0.5);

		z-index: 42;
	}


	&.-show-overlay {
		#{$c}__overlay {
			display: block;
		}
	}


	@include for-phone-landscape-up {
		width: 810px;
		max-width: 810px;
		margin-top: 0;
	}

	@include for-desktop-up {
		width: 1038px;
		max-width: 1038px;

		border: 3px solid #794c57;
		border-bottom: 0;


		&__overlay {
			&:before {
				content: '';

				position: absolute;
				top: -3px;
				right: 240px;

				width: 795px;
				height: 3px;

				background-color: #613C45;
			}

			&:after {
				content: '';

				position: absolute;
				top: -7px;
				right: 2px;

				width: 238px;
				height: 7px;

				background-color: rgba(#000, 0.5);
			}
		}
	}

	@include for-desktop-big-up {
		&:before {
			top: -5px;
			right: -6px;
			left: -6px;


			border-left: 3px solid rgba(#000, 0.3);
			border-right: 3px solid rgba(#000, 0.3);

			border-radius: 3px;
		}
	}
}





/**
 * Main content top menu.
 */
.top-menu {
	$c: &;

	position: relative;

	flex: 0 0 auto;
	display: flex;
	flex-flow: row nowrap;

	height: 40px;
	margin: 1px 1px 0 1px;

	border-top: 2px solid #655443;

	background: #473525;


	&:after {
		content: '';

		position: absolute;
		bottom: -13px;
		left: 0;

		width: 100%;
		height: 14px;

		background: url('#{$gfx-path}/themes/#{$theme}/shared/menu_bg_bot.png') 0 0 repeat-x;

		z-index: 31;
	}


	&__item {
		$item: #{$c}__item;

		position: relative;

		flex: 0 0 auto;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		min-width: 90px;
		margin: 1px 0 0 1px;

		border-top: 1px solid #625644;

		background: linear-gradient(#58462a 60%, #483727);


		&:nth-child(1) {
			margin-left: 0;


			#{$item}-icon {
				margin-left: 5px;
			}
		}


		// component
		.c-notification {
			position: absolute;
			top: -8px;
			right: -2px;

			z-index: 1;
		}


		&.-c-sidebar-indicator {
			width: 50px;
			min-width: 50px;

			margin-left: auto;

			border-top: 0;
			border-left: 1px solid #473525;

			background: #1d150f;
			background: linear-gradient(#1d150f 0%, #483727);
		}


		&.-c-text-hidden {
			flex-basis: auto;

			min-width: 0;


			#{$item}-name {
				display: none;
			}
		}

		&.-c-hide-mobile {
			display: none;
		}

		&.-tab-home {
			padding-right: 5px;


			#{$item}-icon {
				top: -1px;

				font-size: 24px;


				&:before {
					content: '\e801';
				}
			}
		}

		&.-tab-myteam {
			#{$item}-icon {
				&:before {
					content: '\e817';
				}
			}
		}

		&.-tab-world {
			#{$item}-icon {
				&:before {
					content: '\e802';
				}
			}
		}

		&.-tab-arena {
			#{$item}-icon {
				&:before {
					content: '\e830';
				}
			}
		}

		&.-tab-forge {
			#{$item}-icon {
				font-size: 24px;


				&:before {
					content: '\e978';
				}
			}
		}

		&.-tab-clans {
			#{$item}-icon {
				top: 2px;
				left: 2px;

				font-size: 18px;


				&:before {
					content: '\e833';
				}
			}
		}

		&.-tab-kage {
			#{$item}-icon {
				top: 1px;
				left: 2px;

				font-size: 28px;


				&:before {
					content: '\e897';
				}
			}

			#{$item}-name {
				padding-left: 3px;
			}
		}

		&.-tab-premium {
			#{$item}-icon {
				margin: 0 7px;

				font-size: 24px;

				color: #ea832f;


				&:before {
					content: '\e826';
				}
			}

			&:hover,
			&.-c-sel,
			&.-c-sel:hover {
				#{$item}-icon,
				#{$item}-name {
					color: #ff6b11;
				}
			}
		}

		&.-tab-sidebar {
			#{$item}-icon {
				margin-left: 4px;

				font-size: 24px;

				color: #a98560;


				&:before {
					content: '\e830';
				}
			}
		}


		&:hover {
			#{$item}-icon {
				color: #d29e51;
			}

			#{$item}-name {
				color: #d29e51;
			}
		}

		&.-c-new {
			#{$item}-icon {
				@include color-flashing('colorFlashingMenuNew', #ff6c00);

				animation: colorFlashingMenuNew 1s infinite alternate;
			}
		}

		&.-c-sel,
		&.-c-sel:hover {
			background: linear-gradient(#46371f 0%, #231a0f);
			border-color: #3a2d19;


			&:before {
				content: '';

				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				border: 1px solid;
				border-color: #332818 #281c11 #281c11 #281c11;
				border-bottom: 0;
			}


			&:after {
				content: '';

				position: absolute;
				right: 0;
				bottom: -14px;
				left: 0;

				height: 15px;

				border-top: 8px solid #281c11;

				background: #271c11;
				background: linear-gradient(#291d12 0%, rgba(#291d12,0));

				z-index: 32;
			}


			#{$item}-icon,
			#{$item}-name {
				color: #f7bb63;
			}
		}

		&.-c-hide,
		&.-c-hide:hover {
			background: linear-gradient(#4D3D21 0%, #483727);
			border-color: #4D3D21;


			#{$item}-icon,
			#{$item}-name {
				color: #6B583D;
			}
		}
	}

	&__item-icon {
		position: relative;

		flex: 0 0 40px;

		width: 40px;

		font-size: 20px;
		text-align: center;
		text-shadow: 1px 1px 1px #130f09;

		color: #9a793f;


		&:before {
			@extend %font-nm;

			content: '';
		}
	}

	&__item-name {
		position: relative;

		flex: 1 0 auto;

		padding: 0 20px 0 0;

		font-weight: 700;
		font-style: italic;
		font-size: 0.85rem;
		text-transform: uppercase;
		text-shadow: 1px 1px 3px #130f09;

		color: #c29c64;

		em {
			display: none;
		}
	}



	@include for-phone-landscape-up {
		&__item {
			$item: #{$c}__item;

			&:nth-child(1) {
				#{$item}-icon {
					margin-left: 40px;
				}
			}


			&.-c-sidebar-indicator {
				margin-right: 10px;
			}
		}
	}

	@include for-desktop-up {
		#{$c}__item.-c-sidebar-indicator {
			display: none;
		}

		&__item {
			&.-c-hide-mobile {
				display: flex;
			}
		}

		&__item-name {
			em {
				display: inline-block;
			}
		}
	}
}





/**
 * Content container of left sidebar, content and right sidebar.
 */
.l-content-container {
	position: relative;

	flex: 1 0 auto;
	display: flex;
	flex-flow: row nowrap;

	background: #291d12;



	@include for-desktop-up {
		&.-size-wide {
			.content__outer {
				&:nth-child(1) {
					.content__bg {
						width: 100%;
					}
				}
			}

			.content__inner {
				padding-right: 21px;
			}

			.l-right-col__inner {
				display: none;
			}

			.l-right-col__bot {
				margin-top: -66px;
			}

			.right-col-bot__expand {
				display: flex;
			}

			.l-right-col.-c-expanded {
				.right-col-top__expand {
					display: flex;
				}

				.right-col-bot__expand {
					display: none;
				}
			}
		}
	}
}




/**
 * Bottom content row.
 */
.bottom-content {
	position: relative;

	flex: 0 0 auto;
	justify-self: flex-end;

	height: 178px;

	background: url('#{$gfx-path}/themes/#{$theme}/shared//bottom_bg.jpg') no-repeat 0 0 transparent;


	&:before,
	&:after {
		content: none;

		position: absolute;
		left: -3px;
		bottom: 0;

		width: 3px;
		height: 100%;

		background: linear-gradient(#794c57, #000);
	}

	&:after {
		right: -3px;
		left: auto;
	}



	@include for-desktop-up {
		&:before,
		&:after {
			content: '';
		}
	}
}





.l-left-col {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;

	height: 63px;

	background: #291C10;

	z-index: 50;


	&:before {
		content: '';

		position: absolute;
		top: -6px;
		right: 0;
		left: 0;

		display: block;
		height: 2px;

		background: rgba(#000, 0.25);

		z-index: 1;
	}

	&:after {
		content: '';

		position: absolute;
		top: -4px;
		left: 0;

		display: block;
		width: 100%;
		height: 4px;

		background: #513c36;
		border-top: 1px solid #000;

		z-index: 1;
	}


	&__container {
		position: relative;
	}

	&__outside {
		position: absolute;
		top: 2px;
		left: -117px;

		display: none;
		width: 183px;
		height: 309px;

		z-index: 1;
	}

	&__top {
		display: none;
		width: 73px;
		height: 228px;
		margin-top: 170px;
	}

	&__inner {
		display: flex;
		flex-flow: row nowrap;
	}

	&__bot {
		display: none;
	}



	@include for-phone-landscape-up {
		position: relative;
		right: auto;
		bottom: auto;
		left: auto;

		flex: 0 0 0px;

		width: 0px;
		max-width: 0px;
		height: auto;

		background: none;


		&:before {
			content: none;
		}

		&:after {
			content: none;
		}


		&__container {
			position: relative;

			display: flex;
			flex-flow: column nowrap;

			width: 73px;
			min-height: 400px;
			margin-left: 1px;

			z-index: 50;
		}

		&__outside {
			display: block;

			background: 0 0 no-repeat;
		}

		&__top {
			display: block;

			background: url('#{$gfx-path}/themes/#{$theme}/shared/leftcol_sidebar_bg_top_colored.png') 0 0 no-repeat;
		}

		&__inner {
			flex-direction: column;

			width: 72px;
			padding: 0 8px 0 2px;

			background: url('#{$gfx-path}/themes/#{$theme}/shared/leftcol_sidebar_bg_repeat.png') 0 0 repeat-y;
		}

		&__bot {
			display: block;
			width: 72px;
			height: 157px;

			background: url('#{$gfx-path}/themes/#{$theme}/shared/leftcol_sidebar_bg_bot.png') 0 0 no-repeat;
		}
	}
}


.l-center-col {
	$overlay: '.c-overlay';
	$overlayMessage: '.c-overlay-message';

	position: relative;

	flex-grow: 1;
	width: 100%;


	// show overlay message
	&.-c-show-message {

		// hide overlay loader icon
		> .c-overlay {
			@include c-overlay-active;


			#{$overlay}__loader {
				display: none;
			}
		}

		> .c-overlay-message {
			display: block;
		}
	}


	@include for-phone-landscape-up {

		/*
		// better centering of overlay with slightly visible right col
		> .c-overlay {
			#{$overlay}__loader {
				margin-right: 20px;
			}
		}
		*/


	}

	@include for-desktop-up {

		// better centering of overlay with right col
		> .c-overlay {
			#{$overlay}__loader {
				&.-c-window-centered {
					right: 190px;

					margin-right: auto;
				}

				margin-right: 240px;
			}
		}

		// better centering of overlay message with right col
		> .c-overlay-message {
			#{$overlayMessage}__inner {
				margin-right: 270px;
			}
		}
	}
}






/**
 * Right column in
 *      _right-col.scss
 */
.l-right-col {}






/**
 * Index page description not displayed.
 */
.page-description {
	position: absolute;
	top: 0;

	padding: 2px;

	h1,
	h2,
	p {
		color: #000;
		margin: 0;
		font-size: 1rem;
		line-height: 1.0;
	}

	h1 {
		font-size: 1.4rem;
		color: #000;
		margin-top: 20px;
	}

	h2 {
		font-size: 1.2rem;
	}
}






/**
 * Left sidebar menu.
 */
.left-menu {
	$c: &;

	position: relative;

	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;

	padding: 0 0 0 10px;


	&__item {
		$item: #{$c}__item;

		position: relative;

		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;

		height: 70px;
		width: 80px;
		background: #3b2b13;
		border-left: 1px solid #534835;

		cursor: pointer;


		&:after {
			content: '';

			position: absolute;
			top: -2px;
			right: auto;

			display: block;
			width: 100%;
			height: 1px;

			background: #3f321c;

			z-index: 2;
		}


		i {
			position: relative;
			margin-top: -10px;

			font-size: 26px;
			line-height: 1.1;
			text-shadow: 1px 1px 1px #130f09;

			color: #7d6130;
		}


		&:first-child {
			border-color: #534835;
		}

		&:last-child {
			border-right: 1px solid #534835;
		}


		&:hover {
			#{$item}-name,
			i {
				color: #d29e51;
			}
		}

		&.-c-highlighted {
			#{$item}-name,
			i {
				color: #ff8100;
			}


			&:hover {
				#{$item}-name,
				i {
					color: #ff8100;
				}
			}
		}

		&.-c-alert {
			@include color-flashing('colorFlashingSideAlert', #ffd14e);

			#{$item}-name,
			i {
				animation: 0.3s 3 linear reverse colorFlashingSideAlert;
			}
		}

		&.-c-sel {
			background: #34250e;

			#{$item}-name,
			i {
				color: #FFA200;
			}


			&:after {
				top: -10px;

				height: 11px;

				background: #34250e;
			}
		}


		// component
		.c-notification {
			position: absolute;
			top: -5px;
			right: -8px;

			z-index: 5;
		}
	}

	&__item-name {
		position: relative;

		display: block;
		width: 100%;
		padding: 0 5px;

		font-weight: 700;
		font-style: italic;
		font-size: 0.65rem;
		text-transform: uppercase;
		text-shadow: 1px 1px 3px #130f09;
		text-overflow: ellipsis;
		text-align: center;

		color: #ab8c5f;

		overflow: hidden;
	}


	&.-c-secondary {
		margin-left: auto;
		padding-right: 10px;
	}



	@include for-phone-landscape-up {
		flex-direction: column;

		padding: 10px 0;


		&__item {
			height: 60px;
			width: 60px;
			margin: 0 3px 0 0;

			border: 0;
			border-top: 1px solid #534835;


			&:after {
				top: 0;
				right: -2px;

				width: 1px;
				height: 100%;
			}


			i {
				margin-top: 0;
			}


			&:last-child {
				border-right: 0;
				border-bottom: 1px solid #534835;
			}


			&.-c-sel {
				&:after {
					top: 0;
					right: -11px;

					width: 12px;
					height: 100%;

					border-radius: 0 10px 10px 0;

					background: #34250e;
				}
			}
		}

		&__item-name {
			padding: 0 3px;
		}


		&.-c-secondary {
			margin-left: 0;
			padding-right: 0;
		}
	}

	@include for-desktop-up {
		&__item {
			&.-c-hide-desktop {
				display: none;
			}
		}
	}
}





/**
 *
 */
.content {
	$c: &;

	position: relative;


	&__outer {
		position: relative;

		width: 100%;
		min-height: 450px;


		&.-top-negative {
			margin-top: -250px;
		}

		&.-height-small {
			min-height: 150px;
		}

		&.-height-med {
			min-height: 250px;
		}

		&.-height-full {
			min-height: 0;
		}

		&.-height-large {
			min-height: 550px;


			#{$c}__bg {
				height: 550px;


				img {
					height: 100%;
				}
			}
		}

		&.-height-stretch {
			height: 450px;


			#{$c}__inner {
				height: 100%;
			}

			#{$c}__padding {
				height: 100%;
			}
		}

		&.-height-flex {

			#{$c}__inner {
				min-height: inherit;
			}
		}

		&.-height-center {
			height: 450px;


			#{$c}__inner {
				height: 100%;
			}

			#{$c}__padding {
				display: flex;
				align-items: center;
				justify-content: center;

				height: 100%;
			}
		}

		&.-opacity-med {
			background-color: rgba(#291d12, 0.5);
		}

		&.-opacity-high {
			background-color: rgba(#291d12, 0.8);
		}

		&.-opacity-med,
		&.-opacity-high {
			#{$c}__bg {
				&:before {
					background-image: url('#{$gfx-path}/themes/#{$theme}/shared/shadow_bg_opacity75.png');
				}
			}
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 450px;
		overflow: hidden;

		z-index: 0;


		&:before {
			content: '';

			position: absolute;

			display: block;
			width: 100%;
			height: 100%;

			background: url('#{$gfx-path}/themes/#{$theme}/shared/shadow_bg_opacity50.png') 0 0 no-repeat;
			background-size: 100% 100%;

			z-index: 1;
		}
	}

	&__inner {
		position: relative;

		width: 100%;
		padding: 10px 2px 10px 2px;


		&.-height-full {
			height: 100%;


			#{$c}__container {
				width: 100%;
				height: 100%;
			}
		}

		&.-padding-zero {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	&__padding {
		padding: 0 12px;


		&.-type-story {
			padding: 0 50px;
		}

		&.-type-feature {
			padding: 50px 30px 10px 30px;
		}

		&.-type-button {
			padding: 25px 50px;

			text-align: center;
		}

		&.-type-wide-right {
			// desktop only
		}

		&.-c-overflow-hidden {
			overflow: hidden;
		}

		&.-c-relative {
			position: relative;
		}

		&.-c-flex-wrap {
			flex-wrap: wrap;
		}

		&.-padding-no {
			padding: 0;
		}
	}

	&__overview {
		position: absolute;
		right: 0;
		bottom: -10px;
		left: 0;

		height: 230px;

		border-bottom: 2px solid #473828;

		background-color: rgba(0,0,0, 0.5);

		z-index: 1;


		&:before {
			content: '';

			position: absolute;
			right: 0;
			bottom: -5px;
			left: 0;

			height: 3px;

			background-color: #000;
		}


		// layout
		#{$c}__padding {
			position: relative;
		}


		// component
		.c-divider {
			position: absolute;

			margin-left: 30px;
		}
	}

	&__container {
		position: relative;
	}



	@include for-phone-landscape-up {
		margin-left: 45px;


		&__inner {
			padding-right: 21px;
			padding-left: 30px;
		}

		&__overview {
			left: 14px;
		}
	}


	@include for-desktop-up {
		&__outer {
			&:nth-child(1) {
				#{$c}__bg {
					width: 800px;
				}
			}
		}

		&__bg {
			img {
				width: 100%;
				height: auto;
			}
		}

		&__inner {
			padding-right: 243px;
		}

		&__padding {
			&.-type-wide-right {
				padding-right: 240px;
			}
		}
	}
}



/**
 * User text content section.
 */
.text-content {
	font-size: 0.9rem;
	font-family: sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 1.4;

	color: #bfb6a6; // d0bb96; f2e0c0;


	a {
		text-decoration: underline;

		color: #d0bb96;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	i {
		color: #ff7777;


		&[class^="icon-"] {
			color: inherit;
		}
	}


	div {
		font-family: 'Open Sans', sans-serif;
		font-style: italic;
	}


	// component
	.c-card {
		display: inline-flex;

		margin: 5px;
	}

	.c-item {
		display: inline-flex;

		margin: 5px;
	}
}
