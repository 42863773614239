/**
 * Right column main layout.
 */
.l-right-col {
	$c: &;

	position: relative;
	top: -50px;

	flex: 0 0 0px;

	width: 0px;
	max-width: 0px;


	&__container {
		position: relative;
		right: 233px;

		display: none;
		width: 243px;

		z-index: 40;
		overflow: hidden;
	}

	&__outside {
		position: absolute;
		top: 0;
		left: -117px;

		display: none;
		width: 183px;
		height: 309px;

		z-index: 1;
	}

	&__top {
		position: relative;

		width: 243px;
		height: 69px;

		background: url('#{$gfx-path}/themes/#{$theme}/shared/rightcol_sidebar_bg_top.png') 0 0 no-repeat;
	}

	&__inner {
		display: flex;
		flex-flow: column nowrap;

		width: 245px;
		min-height: 200px;
		padding: 0 7px;

		background: url('#{$gfx-path}/themes/#{$theme}/shared/rightcol_sidebar_bg_repeat.png') 0 0 repeat-y;
	}

	&__bot {
		position: relative;

		width: 243px;
		height: 144px;

		background: url('#{$gfx-path}/themes/#{$theme}/shared/rightcol_sidebar_bg_bot.png') 0 0 no-repeat;

		margin-bottom: 10px;
	}


	&.-c-expanded {
		#{$c}__container {
			right: 243px;

			display: block;
		}
	}



	@include for-phone-landscape-up {
		#{$c}__container {
			right: 18px;

			display: block;
			width: 18px;
		}


		&.-c-expanded {
			#{$c}__container {
				right: 243px;

				width: 243px;
			}

			#{$c}__inner {
				display: flex;
			}

			#{$c}__bot {
				margin-top: 0;
			}
		}
	}

	@include for-desktop-up {
		#{$c}__container {
			right: 243px;

			width: 243px;
		}
	}
}





/**
 * Top section.
 */
.right-col-top {

	&__expand {
		position: absolute;
		top: 12px;
		right: 10px;

		display: none;
		justify-content: flex-end;


		// parent modifier
		.l-right-col.-c-expanded & {
			display: flex;
		}
	}



	@include for-desktop-up {
		&__expand {

			// parent modifier - is never an option on desktop size
			.l-right-col.-c-expanded & {
				display: none;
			}
		}
	}
}





/**
 * Inner section.
 */
.right-col-inner {

}





/**
 * More button section.
 */
.right-col-buttons {
	margin: 0 20px 10px 20px;


	// component
	.c-more-button {
		display: flex;

		margin-bottom: 6px;
	}
}





/**
 * Bottom section.
 */
.right-col-bot {

	&__expand {
		position: absolute;
		bottom: 45px;

		display: none;
		justify-content: center;

		width: 100%;
	}
}